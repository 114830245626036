export default class DrivingInstructorCandidatesForm {

    constructor() {
        this.status_select = null;
        this.instructor_type_select = null;
        this.instructor_city_select = null;
        this.driving_instructor_city_id = null;
        this.driving_instructor_driving_transmission_type = null;
        this.driving_instructor_contract_type = null;
        this.phone_mask = null;

        this.contract_city_id = null;
        this.contract_departure_place_id = null;

         this.init();

        this.bindEvents();
        //window.application.setOnDataChangeListener(this);
    }

    init(){
        this.phone_mask = $("#driving_instructor_candidate_phone_number").mask('00 00 00 00 00');
        this.dropify = $('#profile_image').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });
        this.dropify_upload = $('#driving_instructor_candidate_given_documents').dropify({
            messages: {
                'default': I18n.t('plugins.dropify.messages.default'),
                'replace': I18n.t('plugins.dropify.messages.replace'),
                'remove':  I18n.t('plugins.dropify.messages.remove'),
                'error':   I18n.t('plugins.dropify.messages.error')
            },
            error: {
                'fileSize': I18n.t('plugins.dropify.error.fileSize'),
                'minWidth': I18n.t('plugins.dropify.error.minWidth'),
                'maxWidth': I18n.t('plugins.dropify.error.maxWidth'),
                'minHeight': I18n.t('plugins.dropify.error.minHeight'),
                'maxHeight': I18n.t('plugins.dropify.error.maxHeight'),
                'imageFormat': I18n.t('plugins.dropify.error.imageFormat'),
                'fileExtension': I18n.t('plugins.dropify.error.fileExtension')
            }
        });

        this.driving_instructor_driving_transmission_type = $('#driving_instructor_candidate_driving_transmission_type').selectpicker();
        this.driving_instructor_driving_fuel_type = $('#driving_instructor_candidate_driving_fuel_type').selectpicker();
    }

    bindEvents() {
        this.status_select = $('#driving_instructor_candidate_validation_status').selectpicker();
        this.instructor_type_select = $('#driving_instructor_instructor_type').selectpicker();
        this.driving_instructor_city_id = $('#driving_instructor_city_id').selectpicker();
        this.instructor_city_select = $('#driving_instructor_city').selectpicker();
        this.driving_instructor_driving_transmission_type = $('#driving_instructor_driving_transmission_type').selectpicker();
        this.driving_instructor_contract_type = $('#driving_instructor_contract_type').selectpicker();
        $("#driving_instructor_candidate_departure_place_id").select2();

        $("#driving_instructor_candidate_city_id").off("change").change(function() {
            $("#driving_instructor_candidate_departure_place_id").html("");

            $.each($("#driving_instructor_candidate_departure_place_id").data("departure_places")[$(this).val()], function(idx, data) {
                $("<option>").attr("value", data[1]).html(data[0]).appendTo("#driving_instructor_candidate_departure_place_id");
            });
        });

        $('#driving_instructor_instructor_type').change(function (){
           if($(this).val() == 'self_employed'){
                $('#contract_type_select').addClass('d-none')
           }else{
               $('#contract_type_select').removeClass('d-none')
           }
        });

        this.contract_city_id = $('#contract_city_id').selectpicker();
        this.contract_departure_place_id = $('#contract_departure_place_id').select2();


        $('#contract_city_id').off("change").on( "change", function() {
            $("#contract_departure_place_id").html("");

            $.each($("#contract_departure_place_id").data("departure_places")[$(this).val()], function(idx, data) {
                $("<option>").attr("value", data[1]).html(data[0]).appendTo("#contract_departure_place_id");
            });
        });
        
    }

    onDataChanged(data) {
    }

    onDestroy() {
        this.status_select.selectpicker('destroy');
        this.instructor_type_select.selectpicker('destroy');
        this.driving_instructor_city_id.selectpicker('destroy');
        this.instructor_city_select.selectpicker('destroy');
        this.driving_instructor_driving_transmission_type.selectpicker('destroy');
        this.driving_instructor_contract_type.selectpicker('destroy');
        this.phone_mask.unmask();
    }

}