export default class DrivingTestsResults {

    constructor() {
        this.bindEvents();
    }


    bindEvents() {
        $(".result-checkbox").on("change", function() {
            $(this).closest("form").find(".repassing-max-wait").attr("required", $(this).val() == "result_failed");
        });
    }

}